import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/website-rewrite-2020",
  "title": "Website Rewrite 2020",
  "started": "2020-03-20T00:00:00.000Z",
  "published": "2020-04-04T00:00:00.000Z",
  "backgroundImage": "javascript",
  "tags": ["Gatsby", "React", "GraphQL", "Markdown"],
  "layoutClass": "website-rewrite-2020",
  "thumbnail": "backgrounds/javascript.jpg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`The old website`}</h2>
    <p>{`The previous website was a simple, static, and bootstrap-based website that I quickly threw together on an aeroplane on
the way to and probably from the Velocity Europe 2014 conference.`}</p>
    <p>{`I still quite like the design of the old website (hence keeping the general design for the rewrite).
I should probably say that I am by no means a designer, but I wanted a website that was a little different to a
completely minimalist design.`}</p>
    <div className="image-row old-website-images">
  <Image className="desktop" alt="Old website desktop view" path="blog/2020-03-20-website-rewrite-2020/old-website-desktop.png" mdxType="Image" />
  <Image className="mobile" alt="Old website mobile view" path="blog/2020-03-20-website-rewrite-2020/old-website-mobile.png" mdxType="Image" />
    </div>
    <p>{`The intention was that simple pages would be akin to a business card as they're not going to have all that much content
on them anyway, then the blog pages can expand so that more space for content is available.
I wanted to keep everything in a single page app to improve the responsiveness and to make page transitions less jarring
as they could be achieved by some sort of smooth transition.`}</p>
    <p>{`The old website worked very well on mobile devices with some simple media queries to change how things were rendered.`}</p>
    <p>{`I always had big plans for the old website.
I wanted the blog writing to be as simple as possible, so Markdown seemed an easy method for minimal formatting.
I'm not a fan of had-writing boring HTML documents.
Of course, you can't just serve markdown documents as web pages - well I suppose you can as they are merely plain text
documents but no one seems to do that - so I would need a CI step to build them.
The plan was to also create "API" documents for the frontend to fetch when the user navigated around the website.`}</p>
    <p>{`For some reason - at the time - I liked the idea of a minimalist menu with nice icons instead of words for each item to
keep the size to a minimum.
Unfortunately this fell straight into the mystery meat trap. I've learned my lesson. I'm sorry.
Perhaps it would have been better if I'd been able to design my own icons rather than stick with the few that could be
shoe-horned in from Bootstrap.`}</p>
    <p>{`Speaking of bootstrap, it had somewhat irked me that I included the entirety of the bootstrap framework pretty much just
for a some minimal layout classes, loading about 112KB of CSS.
According to Google PageSpeed Insights, I only actually used 4KB of that!`}</p>
    <p>{`The old website did not work much at all if JavaScript was not available or disabled.
I wonder how many people were not able to read my single blog post because of that.
My guess is zero, but that doesn't mean that it shouldn't be improved with the new revision.`}</p>
    <p>{`The old website did have a nice CI setup to put changes live.
The old website was a simple Git repository hosted on my `}<a parentName="p" {...{
        "href": "https://gitea.io"
      }}>{`Gitea`}</a>{` instance, and my
`}<a parentName="p" {...{
        "href": "https://drone.io"
      }}>{`Drone CI`}</a>{` instance would be invoked when a new revision was pushed to the repository.
The whole pipeline took about 40 seconds from pushing to the changes being put on the server.`}</p>
    <p>{`I think the main failing of the old website was that there was always too far away from what I wanted it to be, but
making it what I wanted it to be would take too much time.
Time I didn't have, especially after having 2 children.`}</p>
    <h3>{`Old website good points`}</h3>
    <ul>
      <li parentName="ul">{`Static website`}</li>
      <li parentName="ul">{`Simple`}</li>
      <li parentName="ul">{`General design - well, I'm sure it wouldn't win any awards, but I like it...`}</li>
      <li parentName="ul">{`Responsive - works well on mobile devices`}</li>
      <li parentName="ul">{`Easy and quick to promote new code to the server`}</li>
    </ul>
    <h3>{`Old website bad points`}</h3>
    <ul>
      <li parentName="ul">{`Bootstrap, and not using much of it`}</li>
      <li parentName="ul">{`Sections are not visible on different URLs`}</li>
      <li parentName="ul">{`Did not work well if JavaScript was disabled`}</li>
      <li parentName="ul">{`Did not fulfil the plans I had for it without significant work`}</li>
      <li parentName="ul">{`Mystery meat menu`}</li>
      <li parentName="ul">{`Very little content`}</li>
      <li parentName="ul">{`Google fonts directly from Google`}</li>
    </ul>
    <h2>{`The new website`}</h2>
    <p>{`This re-write was triggered after I wrote my first `}<a parentName="p" {...{
        "href": "https://reactjs.org/"
      }}>{`React`}</a>{` app.
I wrote a custom alert dashboard for `}<a parentName="p" {...{
        "href": "https://sensu.io/"
      }}>{`Sensu Go`}</a>{`, which is the monitoring platform we use at iWeb.
Hopefully this is something I can write about soon.
The experience was generally positive and quite intuitive to build a reasonably simple web app.
At some point I wondered whether someone had already created method to build static react apps, which would not need
javascript to load the pages that it built.
This might let me build the website that I had envisioned, but failed to build six years ago.`}</p>
    <p>{`It turns out that there was a project that may well do what I wanted!
`}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/"
      }}>{`Gatsby`}</a>{` is a web framework built built around react that can (with some plugins) render
markdown documents to pages, and create "API" documents which can be used to load in subsequent pages, and many other
niceties besides like Sass compilation and image processing.`}</p>
    <p>{`To be absolutely frank, Gatsby is `}<em parentName="p">{`massively`}</em>{` above what a trivial little website like mine requires.
But when given the opportunity to over engineer something - why wouldn't you.
`}<em parentName="p">{`Of course, over engineering is most fun when you don't have to justify it to your client or boss.`}</em>{`
If nothing else, it would be an interesting learning experience to write something else in a React-like way.`}</p>
    <h3>{`Gatsby good points`}</h3>
    <p>{`There are a huge number of features for achieving various pieces of functionality:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.gatsbyjs.org/packages/gatsby-image/"
          }}>{`gatsby-image`}</a>{`, which will automatically resize your images to a few
different sizes and places them in a `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`picture`}</code>{` element containing the various size images for the browser to pick from
based on the browser size.
This even handles nice little features like serving a 20x20px data URL image as a placeholder, then having the full
image fade in when it has been downloaded to the browser.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.gatsbyjs.org/docs/graphql/"
          }}>{`Gatsby GraphQL`}</a>{` allows you to access your website data though
`}<a parentName="p" {...{
            "href": "https://graphql.org/"
          }}>{`GraphQL`}</a>{`, this can then be queried by your pages and components.
The database is not needed when the website is built, but it is used when building your website to static files.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.gatsbyjs.org/docs/sass/"
          }}>{`Sass`}</a>{` - simply install the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`gatsby-plugin-sass`}</code>{` npm package, add it to your
gatsby config file and include your sass/scss files.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.gatsbyjs.org/packages/gatsby-plugin-react-svg/"
          }}>{`gatsby-plugin-react-svg`}</a>{` allows you to import SVG files
and import them like other components.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.gatsbyjs.org/docs/adding-markdown-pages/"
          }}>{`Markdown pages`}</a>{` can be easily added as a source of pages
instead of writing full HTML pages for each page.
The GraphQL entries these create can then be queried to generate additional pages.
I didn't get on all that well with this plugin. More on that later.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.gatsbyjs.org/packages/gatsby-plugin-mdx/"
          }}>{`MDX`}</a>{` is "markdown for the component era". It allows JSX to be
written in markdown files.
This was what I settled on after the issues I had with Markdown pages.
It did not require the extra code to fetch and include pages that was required for the Markdown equivalent, and it
also supported more complex pages as I could simply include a standard component in the page.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.gatsbyjs.org/packages/gatsby-remark-prismjs/"
          }}>{`gatsby-remark-prismjs`}</a>{` adds syntax highlighting to code
blocks in markdown documents.`}</p>
      </li>
    </ul>
    <h3>{`Gatsby bad points`}</h3>
    <p>{`First, I should say that I'm hesitant to call these out as actual bad points.
Because this framework is new to me I've not had opportunity to learn of it's warts yet.
There were some things that I found more complicated than I'd have hoped, and some framework decisions that did not fit
what I had planned for my particular use cases.`}</p>
    <p>{`I would not be surprised if a number of these can be solved quite easily for someone who is more familiar with the
Gatsby framework.
In fact, this list has been written a couple of times as I've found solutions to some of the problems I encountered.`}</p>
    <h4>{`Layout`}</h4>
    <p>{`The first issue my website design had was that each page change would remove the whole page including the layout from
the DOM, then replace it with the next page.
While this seems to have been done for
`}<a parentName="p" {...{
        "href": "https://github.com/gatsbyjs/rfcs/blob/master/text/0002-remove-special-layout-components.md"
      }}>{`very good reasons`}</a>{`
for Gatsby version 2, it interfered with my plans to transition nicely between pages by altering the content rather than
replacing the entire page.`}</p>
    <p>{`Luckily, the `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/packages/gatsby-plugin-layout/"
      }}>{`gatsby-plugin-layout`}</a>{` plugin reimplements the old
behaviour.`}</p>
    <p>{`While this seems straight forward now, it took me two attempts to get this to work without causing the website to load
itself within it's layout - some sort of (luckily limited) page-inception.`}</p>
    <h4>{`Page metadata`}</h4>
    <p>{`By metadata, I don't mean the meta tags added to the HTML document.
When using Markdown, you are able to specify a YAML document of metadata at the top of the Markdown file.
For markdown, this includes the source of the page path, but you can insert whatever metadata you want for your
requirements.
For my Website, I planned to use this for the page size and background image.`}</p>
    <p>{`These metadata items are picked up by Gatsby and entered into the GraphQL database.
By adding configuration to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`gatsby-node.js`}</code>{` file, you can trigger pages to be loaded from GraphQL and pages created
from them.
There was a little edge case that bugged me when creating new markdown files where the development bundle would crash
because the code that created them would try to create a page that did not have a path.
This was fixed with this little addition before passing the page to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`createPage`}</code>{` function so that the page would be
ignored until it was assigned a path:`}</p>
    <span className="prism-title">gatsby-node.js</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`result`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`allMarkdownRemark`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`edges`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` node `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`node`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`frontmatter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`path`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`createPage`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    path`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` node`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`frontmatter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`path`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    component`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` blogPostTemplate`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    context`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Annoyingly, I didn't find a way to add the metadata to standard Javascript pages.
Since migrating to MDX documents, I found
`}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/packages/gatsby-transformer-javascript-frontmatter/"
      }}>{`gatsby-transformer-javascript-frontmatter`}</a>{`
which should do this, though I have a separate problem where this `}<em parentName="p">{`doesn't`}</em>{` seem to work for the 404 page.`}</p>
    <p>{`MDX is annoyingly a tiny bit different with it's meta data in that with Markdown the path is specified in the metadata,
however, with MDX, the path is derived from the path to the .mdx file.
To work around this (so that I didn't need to have structured blog posts in directories) I overwrote the page path with
the value from the metadata in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`gatsby-node.js`}</code>{` file with a little code:`}</p>
    <span className="prism-title">gatsby-node.js</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`exports`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`onCreatePage`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` page`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` actions `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` createPage`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` deletePage `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` actions

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` frontmatter `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`page`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`context `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` page`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`context`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`frontmatter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    frontmatter `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` page`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`context`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`frontmatter
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`frontmatter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`path`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`page`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`path `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` frontmatter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`path`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`deletePage`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`page`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`createPage`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`page`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    path`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` frontmatter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`path
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}</code></pre></div>
    <h4>{`Dependencies`}</h4>
    <p>{`While I probably shouldn't be surprised that a modern (especially JavaScript-based) framework has a lot of dependencies,
I was not prepared for the node_modules directory containing 580MB of them!
I suppose this isn't all that bad as this is only the development dependencies, including the development bundle and
`}<a parentName="p" {...{
        "href": "https://github.com/graphql/graphiql"
      }}>{`GraphiQL`}</a>{` GraphQL browser.
217MB of that is `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/jimp"
      }}>{`Jimp`}</a>{`, the JavaScript Image Manipulation Program.`}</p>
    <h2>{`Website Comparison`}</h2>
    <p>{`As I continue to avoid putting this new website live by finding new things to add to it (different page backgrounds,
better image support from MDX documents, syntax highlighted code snippets, typography, styling tweaks, etc) I thought
I'd take a quick look at the website size since page bloat is a problem the web faces.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`File Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Old Website`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`New Website`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HTML`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`17KB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28KB`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CSS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`120KB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0KB`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`JavaScript`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`84KB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`359KB`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Fonts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50KB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`39KB`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Images`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1438MB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`390KB`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Total`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`1709KB`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`1001KB`}</strong></td>
        </tr>
      </tbody>
    </table>
    <p>{`As it turns out, the CSS is inlined into the HTML document itself, so no separate requests are needed to download the
8KB of CSS that the site uses.
I wonder if that is still done if I had a significant amount of CSS.`}</p>
    <p>{`Amazingly this new website goes against the trend of ever increasing bloat!
However, the same could have been achieved by simply optimising the single large image that the old website used!
Now all I need to do is get into the habit of writing blog posts.
Wish me luck!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      